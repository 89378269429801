/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from "prop-types"
import Header from "./header/Header"
import Footer from "./footer/Footer"
import { Container } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Helmet } from "react-helmet"
import { locals } from '../../local-config'
import { GlobalStyle } from './common/GlobalStyles'
import { myContext } from '../context/LanguageContext'
import { darkenLighten } from "../components/common/ColorAdjust"
import { StyledNavBackground } from './common/StyledTopNavbar'
import PupUpModal from './PupUpModal'

const Layout = ({ children, seoTitle, seoDescription }) => {

  // Modal event and state handling
  const [visible, setVisible] = useState(false)

  const closeModal = () => {
    setVisible(!visible)
  }

  useEffect(() => {
    let pop_status = localStorage.getItem('pop_status')
    const timer = setTimeout(() => {
      // 
      if (!pop_status) {
        setVisible(true)
        localStorage.setItem('pop_status', 1);
      }
    }, 20000);
    return () => clearTimeout(timer);
  }, [])
  // 

// Pass the manuel selected Design from menu to Layout to Design Switcher
const [isDesignToUse, setIsDesignToUse] = useState("");
// 

  // Use these if not delivered from the API (design)
let defaultDesign = {
  page_bg_color: "#ffffff", // white
  page_text_color: "#000000", // black
  header_bg_color: "#000000", // 
  header_text_color: "#ffffff", // 
  main_font: null,
  alt_font1: null,
  alt_font2: null
};

// Use these if not delivered from the API (design) see @ Layout.js
const defaultFonts = {
  // logo_font: "Raleway",
  // page_font: "Baloo 2",
  // title_font: "Pachamama",
  // nav_font: "Raleway"
};

  // 
  return (
    <myContext.Consumer>
      {context => (
        <>
          <Helmet>
            <title>{seoTitle}</title>
            <meta name="description" content={seoDescription}></meta>
            <link rel="preconnect" href="https://fonts.gstatic.com"></link>
            {/* <link href="https://fonts.googleapis.com/css2?family=Megrim&family=Sniglet&display=swap" rel="stylesheet"></link> */}
            {/* {locals.prismicPreviewScript} */}
          </Helmet>
          <StaticQuery
          query={graphql`
          {
            prismic {
              allDesignss {
                edges {
                  node {
                    body {
                      ... on PRISMIC_DesignsBodyDesign {
                        fields {
                          alt_font1
                          alt_font2
                          design_description
                          design_name
                          header_bg_color
                          header_text_color
                          main_font
                          page_bg_color
                          page_text_color
                          secondary_color
                        }
                        primary {
                          design_to_use
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          `}
            render={data => {
              // 
              
              let design = defaultDesign;

              let designToUse = "";

              let DefaultDesignToUse = isDesignToUse;

              if(DefaultDesignToUse){
                designToUse = DefaultDesignToUse;
              } else {
                designToUse = data.prismic.allDesignss.edges[0].node.body[0].primary.design_to_use;
              }
              
              if (data.prismic.allDesignss.edges[0].node.body[0]) {
                const designArr = data.prismic.allDesignss.edges[0].node.body[0].fields;
                for (let index = 0; index < designArr.length; index++) {
                  const element = designArr[index];
                  if (element.design_name === designToUse){
                    design = element;
                  }
                }
              }
              // 

              // check if color from prismic if not aply default color
              // for (let key in design) {
              //   if (design[key] === null) {
              //     design[key] = defaultDesign[key];
              //     
              //   }
              // }

              let fonts = defaultFonts;

              /* if (data.prismic.allDesigns.edges[0]) {
                fonts = data.prismic.allDesigns.edges[0].node.fonts;
              } */

              // check if fonts from prismic if not aply default font
              for (let index = 0; index < fonts.length; index++) {
                const element = fonts[index];
                if (element.font_target === "Logo") {
                  fonts.logo_font = element.google_fonts_name;
                } else if (element.font_target === "Standard") {
                  fonts.page_font = element.google_fonts_name;
                } else if (element.font_target === "Navigation") {
                  fonts.nav_font = element.google_fonts_name;
                }
              }

              for (let key in fonts) {
                if (fonts[key] === null) {
                  fonts[key] = defaultFonts[key];
                }
              }

              // Computed from defaultDesign minus is ligther
              const computedColors = {
                header_link_hover_color: darkenLighten(design.header_text_color, -40),
                // don't show during development
                // header_and_footer_link_visited_color: darkenLighten(design.header_text_color, 40),
                footer_bg_color: darkenLighten(design.header_bg_color, -20),
                footer_link_color: darkenLighten(design.header_bg_color, 140),
                footer_link_hover_color: darkenLighten(design.header_bg_color, 80),
                footer_bottom_bar_bg_color: darkenLighten(design.header_bg_color, -10),
                page_title_color: darkenLighten(design.page_text_color, 60),
                page_link_color: darkenLighten(design.page_text_color, -20),
                page_link_hover_color: darkenLighten(design.page_text_color, 60),
                page_button_hover_bg_color: darkenLighten(design.page_text_color, -10),
                component_bg_color: darkenLighten(design.page_bg_color, -10),
                // dont show during development
                // page_link_visited_color: darkenLighten(design.page_text_color, 10),
              }

              return (
                <>
                {/* <StyledNavBackground /> */}
                <GlobalStyle
                  headerbgcolor={design.secondary_color}
                  headertextcolor={design.header_text_color}
                  pagebgcolor={design.page_bg_color}
                  pagetextcolor={design.page_text_color}
                  logofont={fonts.logo_font}
                  pagefont={fonts.page_font}
                  navfont={fonts.nav_font}
                  computedColors={computedColors}
                  socondaryColor={design.secondary_color}
                />
                  <Header
                    headerbgcolor={design.secondary_color}
                    langContext={context} //Goes all the way down to the languageselector
                    // toChild={isParentData}
                    setIsDesignToUse={setIsDesignToUse}
                  />
                    <Container fluid>{children}
                      <PupUpModal modalState={visible} closeModal={closeModal}/>
                    </Container>
                  <Footer />
                </>
              )
            }}
          />
        </>
      )}
    </myContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout