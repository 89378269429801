import React, { useState, useEffect } from "react"
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
import StyledLink from '../common/StyledLink'
import { useLocation } from '@reach/router'
import { graphql, StaticQuery } from "gatsby"
// import TopNav from '../TopNav'
import { StyledNavbar, StyledNavbarToggle, StyledNavItem, StyledNavbarBrand } from './StyledNavbar'
import Logo from './Logo'
import { myContext } from '../../context/LanguageContext'
// import DesignSwitcher from '../DesignSwitcher'
import LanguageSelector from '../LanguageSelector'
import SocialMedia from "../SocialMedia"

const Header = ({ headerbgcolor, langContext }) => {

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 800);
    });
  }, []);
  
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  // 

  // **********************************************************

  const [scrollDir, setScrollDir] = useState("scrolling up");

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);
    // 

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  // *************************************************************************

  const [expanded, setExpanded] = useState(false);

  const { pathname } = useLocation();
  // 

  return (
    <StaticQuery
      query={graphql`
   {
    prismic {
    allMulti_level_navigations(lang: "de-ch") {
      edges {
        node {
          branding
          logo
          menue_transparent
          body {
            ... on PRISMIC_Multi_level_navigationBody1st_level {
              primary {
                link_text
                link_text_en
                nav_link {
                  ... on PRISMIC_Kontakt {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Page {
                    _meta {
                      uid
                    }
                  }
                }
              }
              type
            }
            ... on PRISMIC_Multi_level_navigationBody2nd_level {
              type
              fields {
                third_level_link {
                  ... on PRISMIC__ExternalLink {
                    target
                    _linkType
                    url
                  }
                  ... on PRISMIC_NewsPage {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Page {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Konzerte {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Zeremonien {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Programmuebersicht {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Aktivitaeten {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Familienprogramm {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Workshops {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Portrait {
                    _meta {
                      uid
                    }
                  }
                }
                third_level_link_text
                link_text_en
              }
              primary {
                link_text
                link_text_en
              }
            }
          }
        }
      }
    }
  }
  }`}

      render={data => {
        // totally transparent = 0.1, oposite = 100
        const defaultSettings = {
          transparentnavbar: 0.1,
        }

        let transparentnavbar = data.prismic.allMulti_level_navigations.edges[0].node.menue_transparent;

        if (transparentnavbar === null || transparentnavbar === 0) {
          transparentnavbar = defaultSettings.transparentnavbar;
        }

        return (
          <Container fluid>
            {/* Logo is used only on phone view */}
            <Logo logo={data.prismic.allMulti_level_navigations.edges[0].node.logo.url} />
            <StyledNavbar
              expand="lg"
              transparentnavbar={scroll ? null : transparentnavbar}
              headerbgcolor={headerbgcolor}
              fixed="top"
              scrolled={scroll ? 1 : 0} // instead of true : false
              pathname={pathname}
              expanded={expanded}
              offset={offset > 230}
              scrollDirUp={scrollDir === "scrolling up"}
            >
              {data.prismic.allMulti_level_navigations.edges[0].node.logo ?
                <myContext.Consumer>
                  {context => (
                    <StyledNavbarBrand 
                    href={!context.isEn ? "/" : "/en/"} 
                    pathname={pathname} scrolled={scroll ? 1 : 0} 
                    headerbgcolor={headerbgcolor}
                    // if lang is en set it back to german otherwise buggy behavieor:
                    // route remains en but lang selector shows "EN"
                      onClick={() => context.isEn ? context.changeLang() : null}
                    >
                      <img
                        src={data.prismic.allMulti_level_navigations.edges[0].node.logo.url}
                        className="d-inline-block align-top logo"
                        alt={data.prismic.allMulti_level_navigations.edges[0].node.logo.alt}
                      />
                    </StyledNavbarBrand>
                  )}
                </myContext.Consumer> : null
              }
              <StyledNavbarBrand
                scrolled={scroll ? 1 : 0} // instead of true : false
                pathname={pathname}
                headerbgcolor={headerbgcolor}
              >
                <myContext.Consumer>
                  {context => (
                    <StyledLink navbarbrand={1} to={!context.isEn ? "/" : "/en/"} className="navbar-brand">
                      {data.prismic.allMulti_level_navigations.edges[0].node.branding}
                    </StyledLink>
                  )}
                </myContext.Consumer>
              </StyledNavbarBrand>
              <StyledNavbarToggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
              <Navbar.Collapse id="basic-navbar-nav">
                {/* <TopNav headerbgcolor={headerbgcolor} location={pathname} langContext={langContext} /> */}
                <Nav className="ml-auto main-nav">
                {/* <Nav as="ul" className="ml-auto main-nav"> */}
                  {/* <DesignSwitcher setIsDesignToUse={setIsDesignToUse} /> */}
                  <myContext.Consumer>
                    {context => (
                      <StyledLink to={!context.isEn ? "/" : "/en/"} className="nav-link" active={pathname === "/" ? "active" : null}>Home</StyledLink>
                    )}
                  </myContext.Consumer>

                  {data.prismic.allMulti_level_navigations.edges[0].node.body.map((navlevel, i) => {

                    if (navlevel.type === "2nd_level") {

                      return (
                        <myContext.Consumer key={i}>
                          {context => (
                            <NavDropdown title={!context.isEn ? navlevel.primary.link_text : navlevel.primary.link_text_en} id="basic-nav-dropdown" key={i}>

                              {navlevel.fields.map((navlink, i) => {
                                // 

                                // Only external links have _linkType
                                if (!navlink.third_level_link._linkType) {

                                  return (
                                    // <NavDropdown.Item as="li" key={i}>
                                    <NavDropdown.Item as="li" key={i} active={pathname === "/" + navlink.third_level_link._meta.uid ? "active" : null}>
                                      {
                                        <StyledLink to={!navlink.third_level_link._meta ? null : context.isEn ?
                                          `/en/${navlink.third_level_link._meta.uid}` : `/${navlink.third_level_link._meta.uid}`}
                                          className="nav-link" active={navlink.third_level_link._meta ?
                                            pathname === `/${navlink.third_level_link._meta.uid}` : null}
                                          state={{ prevPath: pathname }}
                                        >
                                          {!context.isEn ? navlink.third_level_link_text : navlink.link_text_en ? navlink.link_text_en : navlink.third_level_link_text}
                                        </StyledLink>
                                      }
                                    </NavDropdown.Item>
                                  )
                                } else { 
                                  // return null
                                    return (
                                      <NavDropdown.Item as="li" href={navlink.third_level_link.url} key={i}>
                                        {
                                          <a className="StyledLink__StyledLinks-gqXgDn cLUnWm nav-link" href={navlink.third_level_link.url}>
                                            {!context.isEn ? navlink.third_level_link_text : navlink.link_text_en ? navlink.link_text_en : navlink.third_level_link_text}
                                          </a>
                                        }
                                      </NavDropdown.Item>
                                      )
                                    }
                              })}
                            </NavDropdown>
                          )}
                        </myContext.Consumer>
                      )
                    }

                    if (navlevel.type === "1st_level" && navlevel.primary.link_text !== "Home") {
                      // 
                      return (
                        <StyledNavItem as="li" key={i} active={pathname === "/" + navlevel.primary.nav_link._meta.uid ? "active" : null}>
                          {
                            <myContext.Consumer>
                              {context => (
                                <StyledLink to={!navlevel.primary.nav_link._meta ? null : context.isEn ?
                                  `/en/${navlevel.primary.nav_link._meta.uid}` : `/${navlevel.primary.nav_link._meta.uid}`}
                                  className="nav-link" active={navlevel.primary.nav_link._meta ?
                                    pathname === `/${navlevel.primary.nav_link._meta.uid}` : null}>
                                  {!context.isEn ? navlevel.primary.link_text : navlevel.primary.link_text_en}
                                </StyledLink>
                              )}
                            </myContext.Consumer>
                          }
                        </StyledNavItem>
                      )
                    } else {return null}
                  })
                  }
                  <LanguageSelector location={pathname} langContext={langContext} expanded={expanded} scrolled={scroll} />
                  <SocialMedia expanded={expanded} scrolled={scroll} />
                </Nav>
              </Navbar.Collapse>
            </StyledNavbar>
          </Container>
        )
      }}
    />
  )
};

export default Header;
