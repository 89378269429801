import React from 'react'
import { graphql, StaticQuery } from "gatsby"
import { Col, Container } from 'react-bootstrap'
import FooterNav from './FooterNav'
import Address from '../Address'
import FooterSecondaryNav from './FooterSecondaryNav'
import { StyledAddress } from '../common/StyledAddress'
import { FooterRow, FooterBottomBar, BottomLeft, BottomRight} from './styledFooter'
import NewsletterButton  from '../NewsletterButton'
import Sponsoren from './Sponsoren'
import SocialMedia from "../SocialMedia"
import styled from 'styled-components'

const currentDate = new Date();
const year = currentDate.getFullYear();

const Footer = () => {

    return (
        <Container fluid>
            <FooterRow type="sponsoren" xs={2} md={4} 
                        className="justify-content-md-center py-3 py-md-4" 
                        style={{ marginTop: '10vw', backgroundColor: "#0b5a59" }}>
                <Sponsoren />
            </FooterRow>
            <FooterRow className="justify-content-md-center py-3 py-md-4">
                <Col md={3}>
                    <FooterNav />
                    <StyledContainer>
                    <SocialMedia location="footer" />
                    </StyledContainer>
                </Col>
                <Col md={3}>
                    <StyledContainer>
                    <NewsletterButton location="footer" />
                    </StyledContainer>
                </Col>
                <Col md={3}>
                    <StyledAddress location="footer">
                        <Address location="footer"/>
                        <Address location="footer" type="Kontoangaben"/>
                    </StyledAddress>
                </Col>
            </FooterRow>
            <FooterBottomBar>
                <Col sm={6}>
                    <BottomLeft>
                        <FooterSecondaryNav />
                    </BottomLeft>
                </Col>
                <Col sm={6}>
                    <StaticQuery
                        query={graphql`
 {
  prismic {
        allContact_datas {
      edges {
        node {
          branding
        }
      }
    }
  }
}
`}
        render={data => (
            <BottomRight>
                <p className="text-center text-md-right mt-md-2">
                    &copy; {data.prismic.allContact_datas.edges[0].node.branding} {year}
                </p>
            </BottomRight>
        )}
                    />
                </Col>
            </FooterBottomBar>
        </Container>
    )
}

const StyledContainer = styled.div`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap

    text-align: center;

// Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {

}
// Medium devices (tablets, 768px and up, col-md)
@media (min-width: 768px) {

}
// Large devices (desktops, 992px and up, col-lg)
@media (min-width: 992px) {
    text-align: left;
}
// Extra large devices (large desktops, 1200px and up, col-xl)
@media (min-width: 1200px) {

}    
`
export default Footer;

