import styled from "styled-components"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import { hexToRgbA, darkenLighten } from "../common/ColorAdjust"

const StyledNavbar = styled(Navbar)`
  // Extra small devices (portrait phones, less than 576px)
  // No media query for xs since this is the default in Bootstrap

  background-color: ${props =>
    props.pathname !== "/" && props.pathname !== "/en/"
      ? "var(--secondary-color)"
      : props.expanded
      ? "var(--secondary-color)"
      : props.scrolled
      ? "var(--secondary-color)"
      : "transparent"};
  // color: var(--header-link-color);
  text-transform: uppercase;
  text-align: center;

  .navbar-toggler {
    font-size: 1.6rem !important;
    background-color: transparent !important;
  }

  a.navbar-brand {
    text-decoration: none;
    font-size: 1.8rem !important;
  }

  span.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='green' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

  // Phone view menue
  // Space betwen nav items
  .nav-link {
    margin-top: 0.5rem;
    font-size: 1rem !important;
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  .nav-link,
  #basic-nav-dropdown {
    // color: ${props => darkenLighten(props.headerbgcolor, 20)} !important;
    color: var(--header-link-color) !important;
    font-size: ${props =>
      props.expanded === "expanded" ? "1.6rem" : null} !important;
  }

  ul.navbar-nav {
    // Space of the first nav item to top
    margin-top: 4rem;
    padding-bottom: 2rem;
    /* height: ${props =>
      props.expanded === "expanded" ? "100vh" : null} !important; */
  }

  li.dropdown-item a {
    font-size: 1.1rem;
    text-transform: none;
  }

  li.dropdown-item:not(:first-child) {
    margin-top: -15px;
  }

  li.dropdown-item.active {
    background-color: transparent;
  }

  li.dropdown-item.active a {
    text-decoration: underline;
  }

  /*   // nav item external link
  a.dropdown-item:last-child {
    margin-bottom: 1.2rem;
  }

  a.dropdown-item:first-child {
    margin-top: 1.2rem;
  }

  a.dropdown-item {
    text-decoration: none;
    text-transform: none;
    font-size: 21px;
  }
  // */

  a.dropdown-item {
    color: var(--header-link-color) !important;
  }

  /* the body of the dropdownmenu */
  div.dropdown-menu.show {
    border-radius: 20px;
    background: #b17a11;
  }

  /* Mobile Nav scrolling*/
  .dropdown-menu {
    max-height: 300px;
    overflow-y: scroll;
    background-color: ${props => darkenLighten(props.headerbgcolor, 10)};
    text-align: center;
  }

  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
    span.navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
  }

  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
    display: flex !important;

    a.navbar-brand {
      display: flex !important;
    }

    /* No Nav dropdown scrolling*/
    .dropdown-menu {
      max-height: none;
      overflow-y: visible;
    }
  }

  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {
    background-color: transparent;
    height: ${props => (props.scrolled ? "55px" : null)};
    font-size: ${props => (props.scrolled ? "1rem" : "1.3rem")};
    margin-top: 30px;

    .navbar-toggler {
      display: none;
    }

    ul.navbar-nav {
      margin-top: 0;
      // to center the links vertical
      padding-bottom: 5px;
    }

    li.dropdown-item:not(:first-child) {
      margin-top: -5px;
    }

    li.dropdown-item:last-child {
      margin-bottom: 0.5rem;
    }

    li.dropdown-item {
      &:hover {
        background-color: rgba(177, 122, 17, 0.55);
      }
    }

    /* the body of the dropdownmenu */
    div.dropdown-menu.show {
      border-radius: 20px;
      background: rgba(177, 122, 17, 0.44) !important;
    }

    /*   // nav item external link
    a.dropdown-item:last-child {
    margin-bottom: 1rem;
    }

    a.dropdown-item {
    margin-left: 9px;
    text-decoration: none;
    text-transform: none;
    
    &:hover {
      text-decoration: underline;
      width: auto;
    }
  } */

    .main-nav {
      background-color: ${props =>
        props.pathname !== "/" && props.pathname !== "/en/"
          ? "var(--secondary-color)"
          : props.scrolled
          ? "var(--secondary-color)"
          : hexToRgbA(props.headerbgcolor, "0.45")};

      padding: ${props =>
        props.pathname !== "/" && props.pathname !== "/en/"
          ? "0.5rem 1.2rem"
          : props.scrolled
          ? "0.5rem 1.2rem"
          : "0.8rem 2rem"};
      margin-top: ${props =>
        props.pathname !== "/" && props.pathname !== "/en/"
          ? "1.8rem"
          : props.scrolled
          ? "3rem"
          : "2rem"} !important;
      border-radius: 50px;
      margin-right: ${props =>
        props.pathname !== "/" && props.pathname !== "/en/"
          ? "2rem"
          : props.scrolled
          ? "2rem"
          : "3rem"};
    }

    .nav-link,
    #basic-nav-dropdown {
      color: var(--page-bg-color) !important;
      margin: 0;
      font-size: ${props =>
        props.pathname !== "/" && props.pathname !== "/en/"
          ? "1.2rem"
          : props.scrolled
          ? "1.2rem"
          : "1.3rem"};
    }

    a#lang-select {
      color: var(--header-link-color) !important;
      text-decoration: none !important;
      margin-top: ${props => (props.scrolled ? "8px" : "6px")};
      font-size: ${props => (props.scrolled ? "1.1rem" : "1.2rem")};
    }

    div.dropdown-menu.show {
      background-color: ${props =>
        props.pathname !== "/" && props.pathname !== "/en/"
          ? "var(--secondary-color)"
          : props.scrolled
          ? "var(--secondary-color)"
          : hexToRgbA(props.headerbgcolor, "0.55")} !important;
      margin-top: ${props => (props.scrolled ? "1rem" : "0.8rem")} !important;
      text-align: left;
      line-height: 0.5;
      border-radius: 1rem;
    }

    .dropdown-menu a {
      font-size: ${props => (props.scrolled ? "0.9rem" : "1.2rem")} !important;
    }

    .dropdown-item {
      border-radius: 1rem;
      &:hover {
        background-color: ${props => hexToRgbA(props.headerbgcolor, "0.95")};
      }
    }
  }

  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {
  }
`

const StyledNavbarToggle = styled(Navbar.Toggle)`
  background-color: var(--secondary-color) !important;
  border: 0;
  &:focus {
    outline: none;
  }
`
const StyledNavItem = styled(Nav.Item)`
  a.nav-link {
    text-decoration: ${props =>
      props.active === "active" ? "underline" : "none"};
  }
`
const StyledNavbarBrand = styled(Navbar.Brand)`
  // Extra small devices (portrait phones, less than 576px)
  // No media query for xs since this is the default in Bootstrap
  /* font-family: var(--logo-font); */

  /* .navbar-brand{
  margin: 0 !important;
  padding: 0 !important;
} */

  a.navbar-brand {
    color: var(--page-bg-color) !important;
    font-size: 1.6rem !important;
    text-align: left;
    display: ${props =>
      props.pathname !== "/" && props.pathname !== "/en/"
        ? "block"
        : props.scrolled
        ? "block"
        : "none"};
    font-family: "Pachamama";
  }

  img.logo {
    display: ${props =>
      props.pathname !== "/" && props.pathname !== "/en/"
        ? "block"
        : props.scrolled
        ? "block"
        : "none"} !important;
    /* aplies to the logo in the navbar */
    width: 40px;
  }

  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
  }
  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
    a.navbar-brand {
      font-size: 1.8rem !important;
    }

    img.logo {
      /* aplies to the logo in the navbar */
      width: 60px;
    }
  }
  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {
    a.navbar-brand {
      display: none !important;
    }

    img.logo {
      display: block !important;
      width: ${props =>
        props.pathname !== "/" && props.pathname !== "/en/"
          ? "10vw"
          : props.scrolled
          ? "8rem"
          : "10rem"};
      position: absolute;
      top: ${props =>
        props.pathname !== "/" && props.pathname !== "/en/"
          ? "20px"
          : props.scrolled
          ? "20px"
          : "40px"};
      left: ${props =>
        props.pathname !== "/" && props.pathname !== "/en/"
          ? "40px"
          : props.scrolled
          ? "40px"
          : "80px"};
    }
  }
  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {
  }
`

export { StyledNavbar, StyledNavbarToggle, StyledNavItem, StyledNavbarBrand }
