import React from 'react'
import { graphql, StaticQuery } from "gatsby"
import { Row, Col } from 'react-bootstrap'

function Sponsoren() {
  return (
      <StaticQuery
          query={graphql`
 {
  prismic {
        allContact_datas {
      edges {
        node {
          sponsoren {
            link {
              ... on PRISMIC__ExternalLink {
                target
                url
              }
            }
            logo
        }
        }
      }
    }
  }
}
`}
          render={data => {
              return (
              <>
                  { data.prismic.allContact_datas.edges[1].node.sponsoren.map((img, i) => {
                      return (
                        <Col key={i} className="my-0">
                          <a href={img.link.url}>
                              <img src={img.logo.url} style={{width: "100%", height: "auto"}} />
                          </a>
                          </Col>
                      )
                  })}
              </>
              )
           }}
      />
  )
}

export default Sponsoren