import React from 'react'
import {Row, Col } from 'react-bootstrap'
import RichText from "../components/richText"
import { PrismicRichText } from '@prismicio/react'

export default function PageTitle({ node, pageTitle }) {

    // 
   
    let hasTopBanner = false

    if(node){

        if(node.body) {
            for (let index = 0; index < node.body.length; index++) {
            const element = node.body[index];
            if(element.fields){
                // 
                for (let index = 0; index < element.fields.length; index++) {
                    const item = element.fields[index];
                    if(item.position === "Ganz oben") {
                        // 
                        hasTopBanner = true
                    }
                }
            }
        }
    }

        const pageTitle = node.page_title

        return (
            <>  
                {/* Don't show when emty */}
                {!pageTitle ? null : pageTitle[0] === undefined ? null : pageTitle[0].text !== "" ? 
                <Row style={{ marginTop: "6rem" }}>
                    <Col md={1} lg={2}></Col>
                    <Col className="mt-4 mt-lg-5 mb-md-4 pt-lg-4">
                        {hasTopBanner ? <PrismicRichText
                            field={pageTitle}
                            components={{
                                heading1: ({ children }) => <h1 className='top-banner'>{children}</h1>
                            }}
                        /> : 
                            <RichText render={pageTitle} />}
                    </Col>
                    <Col md={1} lg={2}></Col>
                </Row> : null}
            </>
        )

    } else {
        
        return (
            <Row style={{ marginTop: "6rem" }}>
                <Col md={1} lg={2}></Col>
                <Col className="mt-4 mt-lg-5 mb-md-4 pt-lg-4">
                    <RichText render={pageTitle} />
                </Col>
                <Col md={1} lg={2}></Col>
            </Row>
            )
}
}
