import React from "react"
import StyledButton from "./common/StyledButton"

function NewsletterButton(props) {
  console.log("location", props.location)

  if (props.parent === "AddressEn") {
    return (
      <StyledButton
        location={props.location}
        href="https://pachamamafestival.us5.list-manage.com/subscribe/post?u=a96bedc1543ed13a69ee6b665&amp;id=9e79d71daa"
        target="_blank"
      >
        Subscribe to newsletter
      </StyledButton>
    )
  } else {
    return (
      <StyledButton
        location={props.location}
        href="https://pachamamafestival.us5.list-manage.com/subscribe/post?u=a96bedc1543ed13a69ee6b665&amp;id=9e79d71daa"
        target="_blank"
      >
        Newsletter abonnieren
      </StyledButton>
    )
  }
}

export default NewsletterButton
