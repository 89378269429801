import React from 'react'
import { graphql, StaticQuery } from "gatsby"
import facebookIcon from '../images/facebook-square-brands.svg'
import styled from 'styled-components'

function SocialMedia(props) {
    // 
    return (
        <StaticQuery
            query={graphql`
        {
  prismic {
    allContact_datas {
      edges {
        node {
          facebook
        }
      }
    }
  }
}
      `}
            render={data => {
                // 
                return (
                    <a href={data.prismic.allContact_datas.edges[1].node.facebook}>
                        <StyledImg src={facebookIcon} location={props.location} expanded={props.expanded} scrolled={props.scrolled} />
                    </a>
                )
            }}
        />
    )
}

const StyledImg = styled.img`
  height: 28px;
  width: 28px;
  margin-left: ${props =>
    props.location === "footer"
      ? null
      : props.expanded === "expanded"
      ? null
      : "10px"};
  margin-top: ${props =>
    props.expanded ? "20px" : props.scrolled ? "11px" : "3px"} !important;
  margin-bottom: ${props =>
    props.expanded || props.location === "footer" ? "20px" : null};
  filter: ${props =>
    props.location === "footer"
      ? "invert(99%) sepia(58%) saturate(987%) hue-rotate(133deg) brightness(107%) contrast(101%)"
      : "invert(95%) sepia(1%) saturate(2196%) hue-rotate(359deg) brightness(103%) contrast(89%)"};
`
export default SocialMedia