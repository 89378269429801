import React from "react"
import Modal from "react-bootstrap/Modal"
import styled from "styled-components"
import NewsletterButton from "./NewsletterButton"
import RichText from "../components/richText"
import { graphql, StaticQuery } from "gatsby"

function PupUpModal({ modalState, closeModal }) {
  return (
    <StaticQuery
      query={graphql`
        {
          prismic {
            allContact_datas {
              edges {
                node {
                  newsletter_modal_title
                  newsletter_modal_text
                }
              }
            }
          }
        }
      `}
      render={data => {
        const modalTitle =
          data.prismic.allContact_datas.edges[1].node.newsletter_modal_title
        const modalText =
          data.prismic.allContact_datas.edges[1].node.newsletter_modal_text
        //

        return (
          <StyledModal
            show={modalState}
            onHide={closeModal}
            backdrop="static"
            keyboard={false}
            size="xl"
            centered
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <RichText render={modalTitle} />
            </Modal.Header>
            <Modal.Body>
              <RichText render={modalText} />
            </Modal.Body>
            <Modal.Footer>
              <NewsletterButton />
            </Modal.Footer>
          </StyledModal>
        )
      }}
    />
  )
}

export default PupUpModal

const StyledModal = styled(Modal)`
  // Extra small devices (portrait phones, less than 576px)
  // No media query for xs since this is the default in Bootstrap

  /* the transparent bg-color of the window arround the modal */
  background-color: var(--modal-bg-color);

  .modal-content {
    background-color: #5e3e00;
    border-radius: 3.3rem;
    border: none;
    margin-left: 15px;
  }

  .modal-header {
    border-bottom: none;
    padding: 1.5rem 2rem;

    h2 {
      color: var(--secondary-color) !important;
    }

    button.close {
      font-size: 4rem;
      color: var(--secondary-color) !important;
    }
  }

  .modal-body {
    padding: 0 2rem;

    p {
      color: var(--secondary-color) !important;
    }
  }

  .modal-footer {
    border-top: none;
    padding: 0.5rem 2rem;

    a {
      color: #5e3e00 !important;

      &:hover {
        background-color: var(--secondary-color);
        color: var(--page-bg-color) !important;
        font-weight: bold;
        border-color: var(--secondary-color) !important;
      }
    }

    .btn.btn-primary {
      border-color: var(--secondary-color) !important;
    }
  }
  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
  }
  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
  }
  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {
    .modal-content {
      border-radius: 5.3rem;
    }

    .modal-header {
      padding: 3.5rem 5rem;
    }

    .modal-body {
      padding: 0 5rem;
    }

    .modal-footer {
      padding: 1.5rem 5rem;
    }
  }
  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {
  }
`
