import { createGlobalStyle } from "styled-components"
import Pachamama from "../../../src/fonts/Pachamama-Regular.otf"
import Mali from "../../../src/fonts/Mali-Regular.ttf"

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'Pachamama';
  src: url(${Pachamama}) format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Mali";
  src: url(${Mali})  format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap

html {
  --header-bg-color: ${props => props.headerbgcolor};
  --header-link-color: ${props => props.headertextcolor};
  --footer-bg-color: ${props => props.computedColors.footer_bg_color};
  --footer-text-color: ${props => props.computedColors.footer_link_color};
  --footer-link-color: var(--footer-text-color);
  --footer-link-hover-color: ${props =>
    props.computedColors.footer_link_hover_color};
  --footer-bottom-bar-bg-color: ${props =>
    props.computedColors.footer_bottom_bar_bg_color};
  --header-link-hover-color: ${props =>
    props.computedColors.header_link_hover_color};
  --header-link-visited-color: ${props =>
    props.computedColors.header_and_footer_link_visited_color};
  --page-bg-color: ${props => props.pagebgcolor};
  --page-title-color: ${props => props.computedColors.page_title_color};
  --page-text-color: ${props => props.pagetextcolor};
  --page-link-color: ${props => props.computedColors.page_link_color};
  --page-link-hover-color: ${props =>
    props.computedColors.page_link_hover_color};
  --page-button-hover-bg-color: ${props =>
    props.computedColors.page_button_hover_bg_color};
  --page-button-hover-color: ${props => props.pagebgcolor};
  --page-link-visited-color: ${props =>
    props.computedColors.page_link_visited_color};
  --component-bg-color: ${props => props.computedColors.component_bg_color};
  --portrait-img-bg: ${props => props.portraitimagebg};
  --portrait-img-text-color: ${props => props.portraitimgtextcolor};
  --portrait-img-title-color: ${props =>
    props.computedColors.portrait_img_title_color};
  --logo-font: ${props => props.logofont};
  --page-font: ${props => props.pagefont};
  --nav-font: ${props => props.navfont};
  --title-font: ${props => props.titlefont};
  --secondary-color: ${props => props.socondaryColor};
  --secondary-color-dark: #bb7f0c;
  --modal-bg-color: rgba(222, 124, 39, 0.3);

  font-size: 12px !important;

  // style of the data grid programm übersicht
  .MuiDataGrid-root {
    border: none;
  }

  .MuiDataGrid-root .MuiDataGrid-columnsContainer {
    border-bottom: none;
  }

  .MuiDataGrid-root .MuiDataGrid-cell {
    border-bottom: none;
  }

  .MuiDataGrid-root .MuiDataGrid-iconSeparator {
    color: transparent;
  }
  div.MuiDataGrid-cell, div.MuiDataGrid-columnHeaderTitle {
    color: var(--page-text-color);
  }

  div.MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
  }

  div.MuiDataGrid-footerContainer {
    display: none !important;
  }

} // end html

  
body{
  background: var(--page-bg-color);
  font-family: "Mali";
}
  
p {
  font-size: 1.4rem;
}

h1, h2, h3 {
  font-family: "Pachamama";
}

h1{
  font-size: 2.8rem;
}

h1.top-banner {
  color: var(--page-bg-color) !important;
  margin-bottom: 8rem;
}

h2{
  font-size: 2.6rem;
  margin-bottom: 2rem;
}

h3 {
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

h4 {
  font-weight: bold;
  margin-bottom: 1rem;
}

a {
  /* color: red !important; */
  text-decoration: underline;
  color: var(--page-link-color) !important;

  &:hover, &:focus{
    color: var(--page-link-hover-color) !important;
  }

  &:visited{
    color: var(--page-link-visited-color) !important;
  }
}

li.subject-item a {
  font-style: italic;
}

li.subject-item {
  margin-bottom: 2rem !important;
}

a#lang-select {
  color: var(--header-link-color) !important;
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6, li, address{
  color: var(--page-text-color) !important;
}

li {
  font-size: 1.2rem;
}

// syle can be added in prismic in slice "news"
div.card-title.h5 h1.schrift-kleiner{
  font-size: 2.2rem !important;
  color: var(--page-bg-color) !important;
  margin-top: 1.4rem;
}

h2.news-ticker {
  font-size: 3.6rem;
}

p {
  color: var(--page-text-color) !important;
}

p.center-align{
  text-align: center !important;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

  html{
    font-size: 14px !important;
  }

  h1.top-banner {
    margin-bottom: 12rem;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  html{
    font-size: 16px !important;
  }
  
  p {
    font-size: 1.6rem;
  }

  h1.top-banner {
    margin-bottom: 15rem;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  html {
    font-size: 18px !important;
  }

  p {
    font-size: 1.4rem;
  }

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3rem;
  }

  h2.news-ticker {
  font-size: 2.4rem !important;
}

  p.news-accordion-body {
    font-size: 0.8rem;
  }

  p.news-accordion-body-date {
    font-size: 0.7rem;
    font-weight: bold;
  }

  p.card-text {
    font-size: 20rem;
  }

  // syle can be added in prismic in slice "news"
  div.card-title.h5 h1.schrift-kleiner{
    font-size: 1.6rem !important;
    margin-top: 0;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  html{
    font-size: 20px !important;
  }

  h1.top-banner {
    margin-bottom: 5vw;
  }
}   

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1400px) {

  html{
    font-size: 22px !important;
  }

  h1 {
    margin-top: 4vw;
  }

  h1.top-banner {
    margin-bottom: 16vw;
  }
}   
        `

export { GlobalStyle }
