import styled from 'styled-components'
import { Row } from 'react-bootstrap'

/* 
const StyledButton = styled(Button)`
    background: var(--header-bg-color);
    border-radius: 20%;
` */

const FooterRow = styled(Row)`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap

    background: ${props => props.type === "sponsoren" ? "var(--footer-bg-color)" : "var(--footer-bg-color)"};
    padding-top: 2rem !important;

// Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {

}
// Medium devices (tablets, 768px and up, col-md)
@media (min-width: 768px) {

}
// Large devices (desktops, 992px and up, col-lg)
@media (min-width: 992px) {
    padding-top: 3rem !important;
}
// Extra large devices (large desktops, 1200px and up, col-xl)
@media (min-width: 1200px) {

}
    `

const FooterBottomBar = styled(Row)`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap

background: var(--footer-bottom-bar-bg-color);

// Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {

}
// Medium devices (tablets, 768px and up, col-md)
@media (min-width: 768px) {

}
// Large devices (desktops, 992px and up, col-lg)
@media (min-width: 992px) {
    padding-top: 0.5rem;
}
// Extra large devices (large desktops, 1200px and up, col-xl)
@media (min-width: 1200px) {

}

`

const BottomLeft = styled.div`
    margin: 0 auto;

    @media (max-width: 575px) {
        .nav{
            display: block;
            text-align: center;
            margin: 1rem 0;
        }

        .nav-link{
            padding: 0 !important;

        }
    }
`

const BottomRight = styled.div`

    p {
        color: var(--footer-text-color) !important;
        padding-top: 0.1rem;
        font-size: 0.9rem;
    }

    width: 50%;
    margin: 0 auto;
  `

export { FooterRow, FooterBottomBar, BottomLeft, BottomRight}