import styled from "styled-components"
import Navbar from "react-bootstrap/Navbar"
import { hexToRgbA } from "./ColorAdjust"

const StyledNavBackground = styled.div`
  background-color: var(--header-bg-color);
  width: 10rem;
  height: 2rem;
  position: sticky;
  right: 0;
  top: 0;
`
const StyledImage = styled.img`
  height: 20px;
  width: 20px;
`

const StyledTopNavbar = styled(Navbar)`
  // Extra small devices (portrait phones, less than 576px)
  // No media query for xs since this is the default in Bootstrap

  margin-top: 3rem;
  margin-left: 1rem;
  background-color: transparent !important;

  a {
    text-decoration: none;
  }

  .top-nav.navbar-nav {
    display: flex;
    flex-direction: row !important;
    margin-left: 0;
  }
  
  a#lang-select {
    color: black !important;
    font-weight: bold;
    font-size: 1rem !important;
    margin-left: 18px;
    line-height: 2rem;
  }

  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
  }
  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
  }
  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {
    // Opacity factor should come from TopNav props in Nav and not in Navbar
    background-color: ${props =>
      hexToRgbA(props.headerbgcolor, props.opacity / 100)} !important;
    font-size: 0.8rem;
    height: 30px;
    margin-top: 0;

    .nav-link {
      margin-top: 2rem;
    }

    a#lang-select {
      font-size: 1rem !important;
      padding-top: 22px !important;
      margin-right: 1rem;
      line-height: 1rem;
    }
  }
  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {
  }
`

export { StyledTopNavbar, StyledImage, StyledNavBackground }
