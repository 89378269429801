import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { useLocation } from '@reach/router'

const StyledLogo = styled.img`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap

/* aplies to the logo on the home screen */
width: 80vw;
margin-top: 11rem;

// Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {
    display: none !important; // This logo is only shown in the phone view
}
`

function Logo({ logo }) {

    const { pathname } = useLocation();

    if (pathname === "/") {
        return (
            // Smartphone first screen, only show on phone view (d-sm-none)
            <Row style={{ height: "100vh", backgroundColor: "var(--header-bg-color)" }} className="d-sm-none">
                <Col>
                    <StyledLogo src={logo} className="mx-auto d-block" />
                </Col>
            </Row>
        )
    } else { return null; }
}

export default Logo