import React from "react"
import { Link } from "gatsby"
import { myContext } from '../context/LanguageContext'
import styled from 'styled-components'

const StyledLink = styled(Link)`
    font-size: ${props => props.expanded ? "1.6rem" : null};
    /* further styles in GlobalStyles */
`

function LanguageSelector({ location, langContext, expanded }) {
    // 
    if (!langContext.isEn && location.slice(0, 3) !== "/en") {
        return (
            <myContext.Consumer>
                {context => (
                    // Style in GlobalStyles a#lang-select
                    <StyledLink to={`/en${location}`}
                        onClick={() => context.changeLang()}
                        id='lang-select'
                        expanded={expanded}
                    >
                        | EN |
                    </StyledLink>
                )}
            </myContext.Consumer>
        )
    } else {
        return (
            <myContext.Consumer>
                {context => (
                    <StyledLink to={location.replace("/en/", "/")}
                        // <StyledLink to={location.replace("/" + "en" + "/", "/")} 
                        onClick={() => context.changeLang()}
                        id='lang-select'
                        expanded={expanded}
                    >
                        | DE |
                    </StyledLink>
                )}
            </myContext.Consumer>
        )
    }
}
export default LanguageSelector