// Wrapping the Address component as it is used in different parent components with different styles
import styled from 'styled-components'

const StyledAddress = styled.div`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap

color: ${props => props.pageaddress ? "var(--page-text-color)" : "var(--footer-text-color)"};
text-align: center;


h2{
    font-size: ${props => props.pageaddress ? null : "1.5rem"};
    color: ${props => props.pageaddress ? "var(--page-text-color)" : "var(--footer-text-color)"} !important;
}

address {
    line-height: 1.8;
    font-size: ${props => props.pageaddress ? "1.1rem" : "0.9rem"};
    color: var(--footer-text-color) !important;
}

a{
    color: ${props => props.pageaddress ? "var(--page-link-color)" : "var(--footer-link-color)"} !important;
    text-decoration: ${props => props.pageaddress ? "underline" : "none"};
}

a:hover, a:active, a:focus{
    color: ${props => props.pageaddress ? "var(--page-link-hover-color)" : "var(--footer-link-hover-color)"}!important;
    text-decoration: ${props => props.location === "footer" ? "underline" : null} !important;
}

// Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {

}
// Medium devices (tablets, 768px and up, col-md)
@media (min-width: 768px) {

}
// Large devices (desktops, 992px and up, col-lg)
@media (min-width: 992px) {
    text-align: left;
    width: 70%;
    margin: auto !important;

    address {
        font-size: ${props => props.pageaddress ? null : "1.2rem"}
    }
}
// Extra large devices (large desktops, 1200px and up, col-xl)
@media (min-width: 1200px) {

}
`
const StyledConteiner = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 2rem 0 2rem;
`
/* const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0;
` */
export { StyledAddress, StyledConteiner }
