import styled from 'styled-components'
import { Link } from 'gatsby'

const StyledLinks = styled(Link)`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap
color: ${ props => props.footernav || props.bottombarlink ? "var(--footer-text-color)" : "var(--header-link-color)"} !important;
font-weight: ${props => props.active ? "bold" : null};
text-decoration: ${props => props.active ? "underline" : null} !important;
/* color: ${props => props.active ? "var(--header-link-hover-color) !important" : null}; */
padding: ${ props => props.footernav ? 0 : null};
font-size: ${ props => props.bottombarlink ? "0.9rem" : props.navbarbrand ? "1.2rem" : "1.2rem"} !important;
margin-left: ${props => props.navbarbrand ? "0.1rem" : null} !important;

&:hover, &:focus{
    color: ${props => props.navbarbrand ? null 
    : props.footernav || props.bottombarlink ? "var(--footer-link-hover-color)" 
    : "var(--header-link-hover-color)"}  !important;
    text-decoration: ${ props => props.navbarbrand ? "none !important" : "underline !important"};
}

&:visited{ // Same as color
    color: ${ props => props.footernav || props.bottombarlink ? "var(--footer-text-color)" : "var(--header-link-color)"} !important;
}
// Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {
    font-size: ${props => props.navbarbrand ? "1.5rem" : null} !important;
}
// Medium devices (tablets, 768px and up, col-md)
@media (min-width: 768px) {

}
// Large devices (desktops, 992px and up, col-lg)
@media (min-width: 992px) {

}
// Extra large devices (large desktops, 1200px and up, col-xl)
@media (min-width: 1200px) {

}
`;

export default StyledLinks;
