import React from 'react'
import { Nav } from 'react-bootstrap'
import StyledLink from '../common/StyledLink'
import { useLocation } from '@reach/router';
import { graphql, StaticQuery } from "gatsby"
import { myContext } from '../../context/LanguageContext'
import { StyledNavItem } from '../header/StyledNavbar'

export default function FooterNav() {

  const pathname = useLocation()

    return (
        <StaticQuery
            query={graphql`
 {
  prismic {
        allNavigations {
      edges {
        node {
          navigation_links {
            lable
            link_text_en
            link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                }
              }
              ... on PRISMIC_Portrait {
              _meta {
                uid
              }
            }
            ... on PRISMIC_Kontakt {
                _meta {
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
}
`
}
            render={data => {
              return (
          <Nav className="flex-column mb-4">
              <StyledNavItem className="mt-1">
                  <StyledLink 
                  footernav 
                  to="/" 
                  className="nav-link text-center text-md-left" 
                  active={pathname === "/" ? "active" : null}
                  >
                    Home
                    </StyledLink>
                  </ StyledNavItem>
              {
                // FB at ....[0]
                data.prismic.allNavigations.edges[1].node.navigation_links.map((link, i) => {
                      return (
                        <myContext.Consumer>
                          {context => (
                          <StyledNavItem key={i}>
                            {/* turnary expression because homepage has no _meta what causes problems */}
                          <StyledLink footernav="true" to={link.link._meta ?
                           `/${link.link._meta.uid}` : null} className="nav-link text-center text-md-left" 
                            active={link.link._meta ? pathname === `/${link.link._meta.uid}` : null}
                            >
                                {!link.link._meta ? null : context.isEn ? link.link_text_en : link.lable}
                              </StyledLink>
                          </StyledNavItem>
                          )}
                        </myContext.Consumer>
                      )
                  })
              }
          </Nav>
              )
            }
          }
            />
    )
}
