import React from 'react'
import { Nav } from 'react-bootstrap'
import StyledLink from '../common/StyledLink'
import { StyledNavItem } from '../header/StyledNavbar'
import { useLocation } from '@reach/router';

export default function FooterSecondaryNav() {
    const { pathname } = useLocation();

    return (
        <Nav>
            <StyledNavItem>
                <StyledLink bottombarlink="true" to="/datenschutzerklaerung" className="nav-link" active={pathname === "/datenschutzerklaerung" ? "active" : null}>
                    Datenschutzerklärung
                </StyledLink>
            </StyledNavItem>
            <StyledNavItem>
                <StyledLink bottombarlink="true" to="/impressum" className="nav-link" active={pathname === "/impressum" ? "active" : null}>
                    Impressum
                </StyledLink>
            </StyledNavItem>
        </Nav>
    )
}