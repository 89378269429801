import React from "react"
import { graphql, StaticQuery } from "gatsby"
import NewsletterButton from "./NewsletterButton"
import parse from "html-react-parser"
import { StyledConteiner } from "./common/StyledAddress"
import { element } from "prop-types"

export default function Address(props) {
  //
  return (
    <StaticQuery
      query={graphql`
        {
          prismic {
            allContact_datas {
              edges {
                node {
                  adressen {
                    iban
                    title
                    address_type
                    legal_name
                    email
                    first_name
                    last_name
                    phone
                    place
                    street
                    where_to_show
                    zip_code
                    emeded_google_map
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        //

        const googleMap =
          data.prismic.allContact_datas.edges[1].node.adressen[2]
            .emeded_google_map[0].text

        // has to be replaced with a hardcoded data version from api as like this no translation and not consistent
        let contactAddress = {}
        let legalAddress = {}
        let festivalAddress = {}
        let bankAddress = {}

        if (data.prismic.allContact_datas.edges[1].node.adressen) {
          const contactData =
            data.prismic.allContact_datas.edges[1].node.adressen
          for (let index = 0; index < contactData.length; index++) {
            const element = contactData[index]

            if (element.address_type === "Kontaktadresse") {
              contactAddress = element
            }

            if (element.address_type === "Kontoangaben") {
              bankAddress = element
            }

            if (element.address_type === "Firmenadresse") {
              legalAddress = element
            }

            if (element.address_type === "Festivaladresse") {
              festivalAddress = element
            }
          }
          //

          // For testing purpose
          /* const contactAddress = {
            legal_name: "Pachamama Festival",
            first_name: "",
            last_name: "",
            street: "",
            zip_code: "8037",
            place: "Zürich",
            phone: "",
            email: ""
          } */

          const fullName =
            (contactAddress.first_name ? contactAddress.first_name + " " : "") +
            (contactAddress.last_name ? contactAddress.last_name : "")

          if (props.pathname === "/en/kontakt") {
            return (
              <>
                <h2 className="mt-1 text-center text-md-left">
                  Contact Address
                </h2>
                <address>
                  {contactAddress.legal_name}{" "}
                  {contactAddress.legal_name ? <br /> : null}
                  {fullName} {fullName ? <br /> : null}
                  {contactAddress.street}{" "}
                  {contactAddress.street ? <br /> : null}
                  {contactAddress.zip_code} {contactAddress.place}{" "}
                  {contactAddress.place ? <br /> : null}
                  {contactAddress.email ? (
                    <a href={`mailto:${contactAddress.email}`} target="blank">
                      {contactAddress.email}
                    </a>
                  ) : null}
                  {contactAddress.email ? <br /> : null}
                  {contactAddress.phone}
                  {/*                 <a href={`https://${contactAddress.website}`}>{contactAddress.website}</a ><br />
                  <a href={`https://facebook.com/${contactAddress.facebook}`}>{contactAddress.facebook}</a> */}
                </address>
                {props.pathname === "/en/kontakt" ? (
                  <NewsletterButton parent={"AddressEn"} />
                ) : null}
              </>
            )
          }

          if (props.location === "footer" && props.type === "Kontoangaben") {
            console.log("Kontoangaben", bankAddress)
            return (
              <>
                <h2 className="mt-1 mb-0 text-center text-md-left">
                  {bankAddress.title}
                </h2>
                <address className="text-nowrap">
                  {bankAddress.legal_name}, {bankAddress.place} <br />
                  {bankAddress.iban}
                </address>
              </>
            )
          }

          if (props.location === "footer" || props.pathname === "/kontakt") {
            return (
              <>
                <h2 className="mt-1 mb-0 text-center text-md-left">
                  {contactAddress.address_type}
                </h2>
                <address>
                  {contactAddress.legal_name}{" "}
                  {contactAddress.legal_name ? <br /> : null}
                  {fullName} {fullName ? <br /> : null}
                  {contactAddress.street}{" "}
                  {contactAddress.street ? <br /> : null}
                  {contactAddress.zip_code} {contactAddress.place}{" "}
                  {contactAddress.place ? <br /> : null}
                  {contactAddress.email ? (
                    <a href={`mailto:${contactAddress.email}`} target="blank">
                      {contactAddress.email}
                    </a>
                  ) : null}
                  {contactAddress.email ? <br /> : null}
                  {contactAddress.phone}
                  {/*                 <a href={`https://${contactAddress.website}`}>{contactAddress.website}</a ><br />
                  <a href={`https://facebook.com/${contactAddress.facebook}`}>{contactAddress.facebook}</a> */}
                </address>
                {props.pathname === "/kontakt" ? (
                  <NewsletterButton location="Address" />
                ) : null}
              </>
            )
          }

          if (
            props.pathname === "/impressum" ||
            props.pathname === "/datenschutzerklaerung" ||
            props.pathname === "/en/datenschutzerklaerung"
          ) {
            return (
              <>
                {/* <h2 className="mt-1 text-center text-md-left">
                  {legalAddress.address_type}
                </h2> */}
                <address>
                  {legalAddress.legal_name}{" "}
                  {legalAddress.legal_name ? <br /> : null}
                  {fullName} {fullName ? <br /> : null}
                  {legalAddress.street} {legalAddress.street ? <br /> : null}
                  {legalAddress.zip_code} {legalAddress.place}{" "}
                  {legalAddress.place ? <br /> : null}
                  {legalAddress.email ? (
                    <a href={`mailto:${legalAddress.email}`} target="blank">
                      {legalAddress.email}
                    </a>
                  ) : null}
                  {legalAddress.email ? <br /> : null}
                  {legalAddress.phone}
                  {/*                 <a href={`https://${legalAddress.website}`}>{legalAddress.website}</a ><br />
                  <a href={`https://facebook.com/${legalAddress.facebook}`}>{legalAddress.facebook}</a> */}
                </address>
              </>
            )
          }
          if (
            props.pathname === "/anreise" ||
            props.pathname === "/en/anreise"
          ) {
            return (
              <>
                <h2 className="mt-1 text-center text-md-left">
                  {festivalAddress.address_type}
                </h2>
                <StyledConteiner>
                  {/* <StyledIframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2701.077659823985!2d8.532058415728036!3d47.390917610845314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900a6de97e58b3%3A0x143ff23c091ea4bd!2sSchubertstrasse%2021%2C%208037%20Z%C3%BCrich!5e0!3m2!1sde!2sch!4v1648734472548!5m2!1sde!2sch"
                    style="border:0;" 
                    loading="lazy" 
                    frameborder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowfullscreen /> */}
                  {parse(`${googleMap}`)}
                </StyledConteiner>
                <address>
                  {festivalAddress.legal_name}{" "}
                  {festivalAddress.legal_name ? <br /> : null}
                  {fullName} {fullName ? <br /> : null}
                  {festivalAddress.street}{" "}
                  {festivalAddress.street ? <br /> : null}
                  {festivalAddress.zip_code} {festivalAddress.place}{" "}
                  {festivalAddress.place ? <br /> : null}
                  {festivalAddress.email ? (
                    <a href={`mailto:${festivalAddress.email}`} target="blank">
                      {festivalAddress.email}
                    </a>
                  ) : null}
                  {festivalAddress.email ? <br /> : null}
                  {festivalAddress.phone}
                  {/*                 <a href={`https://${festivalAddress.website}`}>{festivalAddress.website}</a ><br />
                  <a href={`https://facebook.com/${festivalAddress.facebook}`}>{festivalAddress.facebook}</a> */}
                </address>
              </>
            )
          }
        }
      }}
    />
  )
}
